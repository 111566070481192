import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MyNavbar from './Navbar';
import Footer from './components/Footer/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';
import ScrollToTop from './components/ScrollToTop';
import ScrollToTopButton from './components/ScrollToTopButton';
import Privacy from './components/Footer/Privacy';
import Terms from './components/Footer/Terms';
import Copyright from './components/Footer/Copyright';
import Data from './components/Footer/Data';
import Refund from './components/Footer/Refund';
import EmployeeManagementSystem from './components/Services/EmployeeManagementSystem';
import PlanInfo from "./components/CreateAccountPlans/PlanInfo";
import CreateAccountForm from "./components/CreateAccountPlans/CreateAccountForm";

import { ToastContainer } from 'react-toastify';
import CourseContent from './components/Courses/CourseContent';
import SoftwareDetails from './components/Solutions/SoftwareDetails';
import AppDetail from './components/Solutions/AppDetail';
import ProjectManagementSystem from './components/Services/ProjectManagementSystem';
import Pricing from './components/Pricing/Pricing';
import StudyPointManagementSystem from './components/Services/StudyPointManagementSystem';
import HRManagementSystem from './components/Services/HRManagementSystem';
import HospitalManagementSystem from './components/Services/HospitalManagementSystem';


const BlogContent = lazy(() => import('./components/Blogs/BlogContent'));
const PostDetails = lazy(() => import('./components/Solutions/PostDetails'));
const Home = lazy(() => import('./components/Home/Home'));
const StudentInformationSystem = lazy(() => import('./components/Services/StudentInformationSystem'));
const OnlineAdmissionSystem = lazy(() => import('./components/Services/OnlineAdmissionSystem'));
const FeesManagementSystem = lazy(() => import('./components/Services/FeesManagementSystem'));
const OnlineExaminationSystem = lazy(() => import('./components/Services/OnlineExaminationSystem'));
const AttendanceManagementSystem = lazy(() => import('./components/Services/AttendanceManagementSystem'));
const HigherEducationSystem = lazy(() => import('./components/Services/HigherEducationSystem'));
const IncomeExpensesManagementSystem = lazy(() => import('./components/Services/IncomeExpensesManagementSystem'));
const InstituteManagementSoftware = lazy(() => import('./components/Services/InstituteManagementSoftware'));
const CollegeManagementSoftware = lazy(() => import('./components/Services/CollegeManagementSoftware'));
const SchoolManagementSoftware = lazy(() => import('./components/Services/SchoolManagementSoftware'));
const EnquiryManagementSystem = lazy(() => import('./components/Services/EnquiryManagementSystem'));
const ShippmentManagementSystem = lazy(() => import('./components/Services/ShippmentManagementSystem'));
const HostelManagmentSystems = lazy(() => import('./components/Services/HostelManagmentSystems'));
const Solutions = lazy(() => import('./components/Solutions/Solutions'));
const Blogs = lazy(() => import('./components/Blogs/Blogs'));
const Team = lazy(() => import('./components/Team/Team'));
const Plan = lazy(() => import('./components/Plan/Plan'));
const Login = lazy(() => import('./components/Login/Login'));
const Organization = lazy(() => import('./components/Organization/Organization'));
const About = lazy(() => import('./components/About/About'));
const ContactUs = lazy(() => import('./components/ContactUs/ContactUs'));
const Become = lazy(() => import('./components/Become/Become'));
const SecondContact = lazy(() => import('./components/SecondContact/SecondContact'));
const Courses = lazy(() => import('./components/Courses/Courses'));
const Career = lazy(() => import('./components/Career/Career'));

function App() {
  return (
    <Router>
      <ScrollToTop />
      <MyNavbar />
      <div style={{ minHeight: 'calc(100vh - 100px)' }}> {/* Adjusts for footer height */}
        <Suspense fallback={<div>Loading...</div>}> {/* You can replace this with a spinner */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/student-information-system" element={<StudentInformationSystem />} />
            <Route path="/online-admission-system" element={<OnlineAdmissionSystem />} />
            <Route path="/fees-management-system" element={<FeesManagementSystem />} />
            <Route path="/online-examination-system" element={<OnlineExaminationSystem />} />
            <Route path="/attendance-management-system" element={<AttendanceManagementSystem />} />
            <Route path="/higher-education-system" element={<HigherEducationSystem />} />
            <Route path="/income-expenses-management-system" element={<IncomeExpensesManagementSystem />} />
            <Route path="/institute-management-software" element={<InstituteManagementSoftware />} />
            <Route path="/college-management-software" element={<CollegeManagementSoftware />} />
            <Route path="/school-management-software" element={<SchoolManagementSoftware />} />
            <Route path="/employee-management-system" element={<EmployeeManagementSystem />} />
            <Route path="/enquiry-management-software" element={<EnquiryManagementSystem />} />
            <Route path="/Shippment-management-software" element={<ShippmentManagementSystem />} />
            <Route path='/hostel-managment-system' element={<HostelManagmentSystems/>}/>
            <Route path='/project-management-system' element={<ProjectManagementSystem/>}/>
            <Route path='/study-point-management-system' element={<StudyPointManagementSystem/>}/>
            <Route path='/hr-management-system' element={<HRManagementSystem/>}/>
            <Route path='/hospital-management-system' element={<HospitalManagementSystem/>}/>
            <Route path="/solutions" element={<Solutions />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blog/:id" element={<BlogContent />} />
            <Route path="/organization" element={<Organization />} />
            <Route path="/plan" element={<Plan />} />
            <Route path="/plan-details" element={<PlanInfo />} />
            <Route path="/next-page" element={<CreateAccountForm />} />
            <Route path="/login" element={<Login />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/about" element={<About />} />
            <Route path="/team" element={<Team />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/terms-and-conditions" element={<Terms />} />
            <Route path="/copyright-policy" element={<Copyright />} />
            <Route path="/data" element={<Data />} />
            <Route path="/refund" element={<Refund />} />
            <Route path="/solutions" element={<Solutions />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blog/:id" element={<BlogContent />} />
            <Route path="/organization" element={<Organization />} />
            <Route path="/plan" element={<Plan />} />
            <Route path="/plan-details" element={<PlanInfo/>}/>
            <Route path="/next-page" element={<CreateAccountForm/>}/>
            <Route path="/login" element={<Login />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/about" element={<About />} />
            <Route path="/team" element={<Team />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/terms-and-conditions" element={<Terms />} />
            <Route path="/copyright-policy" element={<Copyright />} />
            <Route path="/data" element={<Data />} />
            <Route path="/refund" element={<Refund />} />
            <Route path="/become" element={<Become />} />
            <Route path="/Career" element={<Career/>}/>
            <Route path="/second-contact" element={<SecondContact />} />
            <Route path="/post-details/:title" element={<PostDetails />} />
            {/* <Route path="/software-details/:title" element={<SoftwareDetails />} /> */}
            <Route path="/app-details/:title" element={<AppDetail/>}/>
            <Route path="/src/components/Courses/Courses.js" element={<Courses />}/>
            <Route path="/Courses/:id" element={<CourseContent/>} />
            <Route path="/software-details/:title" element={<SoftwareDetails />} />
            <Route path="/pricing" element={<Pricing />} /> 
          
      
            
          </Routes>
        </Suspense>
      </div>
      <Footer />
      <ScrollToTopButton />
      <ToastContainer />
    </Router>
  );
}

export default App;
