import React, { Suspense } from "react";
import { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";

import styles from "./StudyPointManagementSystem.module.css";
import image2 from "../images/online-learning.png";
import image3 from "../images/teacher-mng.png";
import image4 from "../images/fee.jpg";
import SecondContact from "../SecondContact/SecondContact";
import image1 from "../images/studypointdata.jpg";
import imagenew from "../images/student-info-detail.png";

const StudyPointManagementSystem = () => {
  const [showAll, setShowAll] = useState(false);
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const blogs = [
    {
      id: 1,
      title: (
        <span style={{ fontSize: "18px" }}>Fees Reminder</span>
      ),
      image:
        "https://cdn3d.iconscout.com/3d/premium/thumb/payment-reminder-3d-icon-download-in-png-blend-fbx-gltf-file-formats--schedule-day-payday-salary-plan-finance-pack-business-icons-9123889.png?f=webp",
    },

    {
      id: 2,
      title: (
        <span style={{ fontSize: "18px" }}> Fees Tracking</span>
      ),
      image:
        "https://cdn3d.iconscout.com/3d/premium/thumb/finance-management-3d-illustration-download-in-png-blend-fbx-gltf-file-formats--expense-tracking-budgeting-tools-personal-saving-money-financial-planning-pack-business-illustrations-8060292.png?f=webp",
    },
    {
      id: 3,
      title: (
        <span style={{ fontSize: "18px" }}>Grading & Assessment Integration </span>
      ),
      image:
        "https://cdn3d.iconscout.com/3d/premium/thumb/assessment-3d-icon-download-in-png-blend-fbx-gltf-file-formats--checklist-to-do-list-task-data-analysis-vol-1-pack-business-icons-5231417.png?f=webp",
    },
    {
      id: 4,
      title: <span style={{ fontSize: "18px" }}>Attendance Monitoring</span>,
      image:
        "https://cdn3d.iconscout.com/3d/premium/thumb/attendance-3d-icon-download-in-png-blend-fbx-gltf-file-formats--check-time-control-jobs-and-career-pack-business-icons-9276167.png?f=webp",
    },
    {
      id: 5,
      title: (
        <span style={{ fontSize: "18px" }}>Personalized Learning Paths</span>
      ),
      image:
        "https://cdn3d.iconscout.com/3d/premium/thumb/learning-path-3d-icon-download-in-png-blend-fbx-gltf-file-formats--education-journey-personal-growth-knowledge-seeker-development-self-improvement-training-pack-business-icons-9490633.png?f=webp",
    },
    {
      id: 6,
      title: <span style={{ fontSize: "18px" }}>Credit-Based System </span>,
      image:
        "https://img.freepik.com/free-vector/paper-money-dollar-bills-blue-credit-card-3d-illustration-cartoon-drawing-payment-options-3d-style-white-background-payment-finances-shopping-banking-commerce-concept_778687-724.jpg",
    },

    {
      id: 8,
      title: (
        <span style={{ fontSize: "18px" }}>
          Real-Time Notifications & Alerts{" "}
        </span>
      ),
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQ_qNOIq1IJVgH_yzbubCLxOm4DZQ5viiE2Q&s",
    },
    {
      id: 9,
      title: (
        <span style={{ fontSize: "18px" }}>Data Analytics & Reporting</span>
      ),
      image:
        "https://cdn3d.iconscout.com/3d/premium/thumb/data-analytics-3d-icon-download-in-png-blend-fbx-gltf-file-formats--logo-technology-business-pack-icons-6149163.png?f=webp",
    },
    {
      id: 10,
      title: (
        <span style={{ fontSize: "18px" }}>
          Cross-Institutional Credit Transfer
        </span>
      ),
      image:
        "https://img.freepik.com/free-vector/bill-receipt-credit-card-3d-illustration-cartoon-drawing-paper-sheet-with-dollar-symbol-credit-card-3d-style-white-background-business-payment-finances-transaction-concept_778687-705.jpg",
    },
    {
      id: 11,
      title: (
        <span style={{ fontSize: "18px" }}> Cloud-Based & Mobile Access</span>
      ),
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRWCgSzryXkjNUv0ED8CzzYCcDJZUt0unxag&s",
    },
    {
      id: 12,
      title: <span style={{ fontSize: "18px" }}>Security & Data Privacy</span>,
      image:
        "https://cdn3d.iconscout.com/3d/premium/thumb/privacy-3d-icon-download-in-png-blend-fbx-gltf-file-formats--padlock-safe-data-lock-secure-cyber-security-pack-crime-icons-7517278.png",
    },
    {
      id: 18,
      title: <span style={{ fontSize: "18px" }}> 24*7 Support</span>,
      image: "https://cdn-icons-png.flaticon.com/512/5167/5167002.png",
    },
  ];

  const containerStyle = {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
  };

  return (
    <>
      <div className="container-fluid">
        <div className="container">
          <div className="row" style={{ position: "relative" }}>
            <div className="col">
              <img
                className={styles.Img2}
                data-aos="flip-left"
                src={image1}
                alt="student_photo"
              />
            </div>
            <div className="col div1 pt-5">
              <h1
                className={styles.h1}
                style={{ width: "900px", height: "200px" }}
              >
                <div className="h1-content fw-bold fs-3 ">
                  <span className={styles.span}>S</span>tudy Point
                  <br />
                  <span className={styles.span}>I</span>nformation Management
                  <br />
                </div>
              </h1>
              <p className={styles.top_text}>
                A Study Point Information System (SPIS) is a digital platform
                designed to manage and streamline academic performance tracking
                in educational institutions. It enables students, teachers, and
                administrators to access and manage data related to study
                points, grades, course progress, and academic achievements. The
                system typically integrates various functionalities such as
                course enrollment, grading, attendance monitoring, and
                performance analysis. By providing a centralized repository of
                academic records, an SPIS helps students track their progress in
                real time, ensuring transparency and better decision-making
                regarding their studies. Additionally, teachers can use the
                system to assess student performance, provide feedback, and
                manage course-related information efficiently.
              </p>
            </div>
          </div>

          <div className="container-fluid p-5 text-center mt-3">
            <h2 className="fs-1 fw-bold">
              <span className={styles.span}>Flexible</span> StudyPoint System:
              Empowering Adaptability
            </h2>
            <p className="mt-4">
              A Flexible StudyPoint System (FSPS) is an advanced academic
              tracking and management framework designed to accommodate the
              diverse learning needs of students while ensuring institutions
              maintain efficiency in academic operations. Unlike rigid grading
              systems, FSPS is built on adaptability, allowing students to earn
              study points based on their individual learning pace, elective
              choices, and academic progress.
            </p>
          </div>
          <div>
            <h2 className="text-center  key-features fw-bold fs-1">
              <span className={styles.span}> Key</span> Features
            </h2>
          </div>

          <div>
            <div style={{ display: "grid", placeItems: "center", height: "" }}>
              <div className={styles.div2}>
                <Container className="mt-5">
                  <Grid container spacing={3}>
                    {blogs.map((blog) => (
                      <Grid item key={blog.id} lg={4} md={6} className="mb-4">
                        <Card className="d-flex justify-content-center align-items-center bg-light">
                          <CardMedia
                            component="img"
                            data-aos="flip-left"
                            image={blog.image}
                            style={{
                              width: "75px",
                              height: "75px",
                              objectFit: "cover",
                            }}
                          />
                          <CardContent>
                            <Typography
                              variant="h5"
                              component="div"
                              className="d-flex justify-content-start fw-bold  align-items-center text-center"
                            >
                              {blog.title}
                            </Typography>
                            <Typography
                              variant="body2"
                              component="p"
                              className="text-center"
                            >
                              {blog.content}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Container>
              </div>
            </div>
          </div>

          <div className="container2 mt-5">
            {/* Phone Mockup */}
            <div className="phone-mockup">
              <img className="img22" data-aos="fade-right" src={imagenew} />
            </div>

            {/* Benefits Section */}
            <div className="benefits-section " data-aos="fade-left">
              <h1 className="pp fw-bold">
                Benefits of{" "}
                <span className="highlight">
                  StudyPoint Information Software
                </span>
              </h1>
              <p className="pp fs-4 fw-bold">With PJSOFTTECH, you get:</p>
              <ul
                style={{ fontSize: "0.500rem", lineHeight: "1" }}
                className=""
              >
                <li>
                  <b>Enhanced Learning Experience:</b> Provides interactive
                  learning materials, including videos, quizzes, and
                  assignments.
                </li>
                <li>
                  <b>Efficient Academic Management:</b> Automates grading and
                  assessment tracking.Simplifies student record management.
                </li>
                <li>
                  <b>Improved Communication:</b> Facilitates communication
                  between students, teachers, and parents.Enables instant
                  messaging, announcements, and reminders.
                </li>
                <li>
                  <b>Data-Driven Insights :</b> Allows access to learning
                  materials anytime, anywhere.Enables remote learning and online
                  classes.
                </li>
                <li>
                  <b>Time and Cost Efficiency:</b> Reduces manual work in
                  grading and administration.Saves costs on printing and
                  physical resources.
                </li>
                <li>
                  <b>Security and Data Protection:</b> Ensures safe storage of
                  academic records.Minimizes risks of data loss or breaches.
                </li>
                <li>
                  <b>Personalized Learning Paths:</b> Adapts course content
                  based on individual student progress.Provides customized
                  recommendations for additional study materials.
                </li>
                <li>
                  <b>Multi-Language Support:</b> Supports different languages
                  for a diverse student base.Helps international students
                  understand materials better.
                </li>
                <li>
                  <b>Cloud-Based Access and Backup:</b> Prevents data loss with
                  automatic cloud backups.Ensures access to files from any
                  location or device.
                </li>
              </ul>
            </div>
          </div>

          <div className={styles.container}>
            <div className={styles.div3}>
              <div className="row">
                <div className="col sub">
                  <h4 className="fw-bold p-1">E-Library & Study Materials</h4>
                  <p>
                    The E-Library & Study Materials feature in StudyPoint
                    Information Software acts as a centralized digital
                    repository where students and teachers can access a wide
                    range of educational resources, including e-books, research
                    papers, lecture notes, and multimedia content. 
                  </p>
                  <h4 className="fw-bold p-1">Interactive Learning Modules</h4>
                  <p>
                    The Interactive Learning Modules bring education to life by
                    incorporating multimedia elements such as video lectures,
                    animations, quizzes, and simulations. Gamified learning
                    techniques, such as achievement badges and AI-powered
                    personalized study paths, help keep students engaged and
                    motivated. 
                  </p>
                  <h4 className="fw-bold p-1">
                    Online Classrooms & Virtual Lectures
                  </h4>
                  <p>
                    The Online Classrooms & Virtual Lectures feature enables
                    remote learning by providing live and recorded lecture
                    sessions. Integrated with platforms like Zoom, Google Meet,
                    and Microsoft Teams, it allows for real-time interaction
                    between students and teachers through live chats, Q&A
                    sessions, and screen-sharing tools. The system also includes
                    attendance tracking, breakout rooms for group discussions,
                    and automated notifications for upcoming sessions.
                  </p>
                </div>
                <div className="col">
                  <h1 className="pt-4 pb-2 text-center fw-bold">
                    <span className={styles.span}> Online Learning</span> &
                    Digital Content System
                  </h1>

                  <img
                    className={styles.Img1}
                    data-aos="flip-left"
                    src={image2}
                    alt="imag"
                  ></img>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.div4}>
            <div className="row">
              <div className="col">
                <h1 className="pt-4 pb-2 text-center fw-bold">
                  <span className={styles.span}> Teacher & </span> Staff
                  Management
                </h1>
                <img
                  className={styles.Img1}
                  data-aos="flip-left"
                  src={image3}
                  alt="imag"
                ></img>
              </div>
              <div className="col sub">
                <h4 className="fw-bold p-1">Teacher Profile & Records Management</h4>
                <p>
                The system maintains comprehensive teacher profiles, including qualifications, experience, assigned subjects, and schedules. It provides a structured database where administrators can update records, track employment history, and manage credentials, ensuring an organized and transparent faculty database.
                </p>
                <h4 className="fw-bold p-1">Attendance & Leave Management</h4>
                <p>
                The platform automates attendance tracking for teachers and staff, ensuring transparency in working hours and leaves. Teachers can request leave digitally, while administrators can monitor absences, approve requests, and generate attendance reports for payroll processing.
                </p>
                <h4 className="fw-bold p-1">Timetable & Scheduling</h4>
                <p>
                The system helps in creating and managing teacher schedules, ensuring proper class distribution and avoiding conflicts. It allows administrators to optimize faculty workload, allocate subjects efficiently, and adjust schedules dynamically in case of changes.
                </p>
              </div>
            </div>
          </div>

          <div className={styles.div5}>
            <div className="row">
              <div className="col sub">
                <h4 className="fw-bold p-1">Student Fee Collection & Billing</h4>
                <p>
                The system automates student fee collection by generating invoices, tracking payments, and sending reminders for pending dues. It supports multiple payment methods, including online banking, credit/debit cards, and digital wallets, ensuring a seamless transaction process.
                </p>
                <h4 className="fw-bold p-1">Fee Structure Customization</h4>
                <p>
                Institutions can configure fee structures based on different programs, grade levels, and student categories. The system allows for installment-based payments, scholarships, and discounts, ensuring flexibility in financial planning.
                </p>
                <h4 className="fw-bold p-1">Scholarships & Financial Aid Management</h4>
                <p>
                The system manages scholarship applications, eligibility criteria, and disbursement of funds. It ensures that deserving students receive financial aid promptly while keeping a transparent record of awarded scholarships.
                </p>
              </div>
              <div className="col">
                <h1 className="pt-4 pb-2 text-center fw-bold">
                  <span className={styles.span}> Fees & Finance</span>  Management
                </h1>
                <img
                  className={styles.Img1}
                  data-aos="flip-left"
                  src={image4}
                  alt="imag"
                ></img>
              </div>
            </div>
          </div>

          <div className={styles.div6}>
            <div className="row div4">
              <div className="col">
                <h1 className="pt-3 pb-2 text-center fw-bold">
                  <span className={styles.span}>E</span> Learning
                </h1>
                <img
                  className={styles.Img1}
                  data-aos="flip-left"
                  src="https://www.scnsoft.com/education-industry/elearning-portal/elearning-portals-cover-picture.svg"
                  alt="img"
                ></img>
              </div>
              <div className="col sub">
                <h4 className="fw-bold p-1">Interactive Learning Platforms</h4>
                <p>
                  Unlocking the Power of Modern Educational Software: Leveraging
                  Technology to Elevate Student Participation. Providing an
                  Immersive Teaching-Learning Experience through Web-Based
                  Platforms. Integrating Student Information Systems (SIS) with
                  Learning Management Systems (LMS) for Enhanced Online
                  Learning.
                </p>
                <h4 className="fw-bold p-1">Faculty Engagement</h4>
                <p>
                  Empowering Educators with Advanced Tools for Seamless
                  Interaction with Today's Learners. Embracing Digital
                  Innovations to Connect Effortlessly with Students. Enabling
                  Information Sharing, Resource Management, Forum Moderation,
                  Syllabus Design, Lecture Delivery, and FAQ Assistance through
                  Integrated Systems.
                </p>
                <h4 className="fw-bold p-1">Students Experience</h4>
                <p>
                  Embracing Diverse Online Activities, Students Effortlessly
                  Participate in Online Assessments, Submit Assignments, Access
                  E-libraries, Engage in Discussions, and More. Stay Updated on
                  Teaching Schedules and Receive Notifications via SMS, Email,
                  or the Dedicated Mobile App Integrated with the Student
                  Information System.
                </p>
              </div>
            </div>
          </div>

          <div className={styles.hidden}>
            <Suspense>
              <SecondContact />
            </Suspense>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudyPointManagementSystem;
