import React, { useEffect, Suspense } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";

import styles from "./ProjectManagementSystem.module.css";
import SecondContact from "../SecondContact/SecondContact";
import image1 from "../images/Project-Management.webp";
import phone from "../images/student-info-detail.png";

const ProjectManagementSystem = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  const blogs = [
    {
      id: 1,
      title: (
        <span style={{ fontSize: "16px" }}>
          {" "}
          Project Planning and Scheduling
        </span>
      ),
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqe6oYB1cUy7eLNoeaOOwrwztD8t9ezYqvLAY_ZYA2HmnjnsCd6mubYQS5yJLTTbt8_LU&usqp=CAU",
    },
    {
      id: 2,
      title: (
        <span style={{ fontSize: "16px" }}> Task & Workflow Management </span>
      ),
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0AqqMhvJmupbvt0T0Y9N6ads6WOcTBU3tkw&s",
    },
    {
      id: 3,
      title: <span style={{ fontSize: "17px" }}> Resource Management </span>,
      image:
        "https://cdn3d.iconscout.com/3d/premium/thumb/employee-management-3d-icon-download-in-png-blend-fbx-gltf-file-formats--human-resource-team-staff-head-hunting-pack-business-icons-10303606.png?f=webp",
    },
    {
      id: 4,
      title: (
        <span style={{ fontSize: "17px" }}> Budgeting & Cost Control </span>
      ),
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQi0V2R1_4PbBL2WLzZaQ6IQHnOwYXlTEqd5A&s",
    },
    {
      id: 5,
      title: (
        <span style={{ fontSize: "16px" }}> Collaboration & Communication</span>
      ),
      image:
        "https://cdn3d.iconscout.com/3d/premium/thumb/collaboration-3d-illustration-download-in-png-blend-fbx-gltf-file-formats--togetherness-cooperation-partnership-communication-business-strategy-pack-illustrations-4377358.png?f=webp",
    },

    {
      id: 6,
      title: <span style={{ fontSize: "17px" }}> Risk & Issue Management</span>,
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8C951SAFiRbpPQh3a5HopL4B_IBCVvXl2PA&s",
    },

    {
      id: 7,
      title: <span style={{ fontSize: "17px" }}>Reporting & Analytics</span>,
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZ7OXyoZTRimJ3x6RXhFOQlltAS7KEBJkDaw&s",
    },

    {
      id: 7,
      title: (
        <span style={{ fontSize: "16px" }}>Time Tracking & Timesheets</span>
      ),
      image:
        "https://cdn3d.iconscout.com/3d/premium/thumb/time-tracking-3d-icon-download-in-png-blend-fbx-gltf-file-formats--productivity-timesheet-checklist-to-do-list-management-pack-business-icons-8432061.png",
    },

    {
      id: 8,
      title: (
        <span style={{ fontSize: "17px" }}> Security & Access Control </span>
      ),
      image:
        "https://cdn3d.iconscout.com/3d/premium/thumb/access-control-3d-icon-download-in-png-blend-fbx-gltf-file-formats--safety-lock-shield-technology-20-pack-science-icons-7067630.png?f=webp",
    },
    
  ];

  return (
    <>
      <div className="container-fluid">
        <div className="container">
          <div className="row" style={{ position: "relative" }}>
            <div className="col">
              <img
                data-aos="flip-left"
                className={styles.Img2}
                src={image1}
                alt="student_photo"
              />
            </div>
            <div className="col div1">
              <h1
                className={styles.h1}
                style={{ width: "900px", height: "180px" }}
              >
                <div className="h1-content fw-bold fs-3">
                  <span className={styles.span}>P</span>roject
                  <br />
                  <span className={styles.span}>M</span>anagement <br />
                </div>
              </h1>
              <p className={styles.top_text}>
                A Project Management System (PMS) is a software tool that helps
                teams plan, execute, and monitor projects efficiently. It
                includes features like task management, scheduling, resource
                allocation, budgeting, and collaboration. PMS ensures smooth
                workflow automation, risk management, and real-time tracking. It
                enhances communication through document sharing and team
                discussions. Reports and analytics provide insights into project
                progress and performance. PMS can be cloud-based, on-premise, or
                industry-specific. Overall, it improves productivity, minimizes
                delays, and optimizes resource utilization.
              </p>
            </div>
          </div>

          <div className="container-fluid p-5 text-center mt-3">
            <h2 className="fs-1 fw-bold">
              <span className={styles.span}>Simplify</span> salary and payroll
              processing for faculty and administrative staff members by
              generating comprehensive Project Management records effortlessly.
            </h2>
            <p className="mt-4">
              A Project Management System can streamline salary and payroll
              processing for faculty and administrative staff by automating
              record-keeping and financial tracking. It enables effortless
              generation of comprehensive payroll records, ensuring accuracy in
              salary calculations, deductions, and benefits. The system
              integrates timesheets, project hours, and leave management to
              ensure precise compensation. Automated workflows reduce manual
              effort, minimizing errors and compliance risks. Real-time reports
              provide insights into payroll expenses and budgeting. Secure
              access controls ensure confidentiality and compliance with
              institutional policies. Overall, it enhances efficiency, accuracy,
              and transparency in payroll management.
            </p>
          </div>
          <div>
            <h2 className="text-center mt-5 key-features fw-bold fs-1">
              <span className={styles.span}> Key</span> Features
            </h2>
          </div>

          <div className={styles.div2}>
            <Container className="mt-5">
              <Grid container spacing={3}>
                {blogs.map((blog) => (
                  <Grid item key={blog.id} lg={4} md={6} className="mb-4">
                    <Card className="d-flex justify-content-center align-items-center bg-light">
                      <CardMedia
                        data-aos="flip-left"
                        component="img"
                        image={blog.image}
                        style={{
                          width: "75px",
                          height: "75px",
                          objectFit: "cover",
                        }}
                      />
                      <CardContent>
                        <Typography
                          variant="h5"
                          component="div"
                          className="d-flex justify-content-start fw-bold align-items-center text-center"
                        >
                          {blog.title}
                        </Typography>
                        <Typography
                          variant="body2"
                          component="p"
                          className="text-center"
                        >
                          {blog.content}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </div>
          

          <div className="container2 mt-5">
            {/* Phone Mockup */}
            <div className="phone-mockup">
              <img className="img22" data-aos="fade-right" src={phone}></img>
            </div>

            {/* Benefits Section */}
            <div className="benefits-section " data-aos="fade-left">
              <h1 className="pp fw-bold">
                Benefits of{" "}
                <span className="highlight">Project Management Software</span>
              </h1>
              <p className="pp fs-4 fw-bold">With PJSOFTTECH, you get:</p>
              <ul
                style={{ fontSize: "0.500rem", lineHeight: "1" }}
                className=""
              >
                <li>
                  <b>Improved Collaboration:</b> Enables teams to work together
                  seamlessly, even in remote or hybrid environments
                </li>
                <li>
                  <b> Enhanced Organization & Planning:</b>Helps structure
                  tasks, set priorities, and allocate resources
                  effectively.Provides visual tools like Gantt charts, Kanban
                  boards, and timelines.
                </li>
                <li>
                  <b>Better Time Management:</b> Automates task scheduling and
                  deadlines, ensuring timely project completion.Reduces wasted
                  time by tracking progress and identifying bottlenecks.
                </li>
                <li>
                  <b> Increased Productivity & Efficiency:</b>Reduces manual
                  work with automation features like task assignment and
                  notifications.Enables teams to focus on high-priority tasks
                  rather than administrative work.
                </li>
                <li>
                  <b>Resource Allocation & Budget Management:</b> Tracks
                  budgets, expenses, and resource utilization.Prevents overuse
                  or underuse of team members and materials.
                </li>
                <li>
                  <b>Real-time Progress Tracking & Reporting:</b> Provides
                  dashboards and reports for data-driven decision-making.Allows
                  stakeholders to monitor progress and address issues
                  proactively.
                </li>
                <li>
                  <b>Risk Management & Issue Tracking:</b> Identifies potential
                  risks and tracks issues before they escalate.Provides
                  contingency plans and corrective actions to mitigate project
                  risks.
                </li>
                <li>
                  <b>Document & File Management:</b> Stores all project-related
                  files in one secure location for easy access. Eliminates
                  confusion from multiple versions of documents.
                </li>
                <li>
                  <b>Scalability & Flexibility:</b> Adapts to different project
                  sizes and complexities. Supports integration with other tools
                  like CRM, ERP, and communication platforms.
                </li>
                <li>
                  <b> Improved Client & Stakeholder Communication:</b> Keeps
                  clients and stakeholders informed with real-time updates and
                  shared dashboards.Enhances transparency, trust, and client
                  satisfaction.
                </li>
              </ul>
            </div>
          </div>

          <div className={styles.container}>
            <div className={styles.div3}>
              <div className="row">
                <div className="col sub">
                  <p>
                    A Project Management System (PMS) is a software solution
                    designed to help organizations plan, execute, monitor, and
                    complete projects efficiently. It provides a centralized
                    platform where teams can collaborate, track progress, manage
                    resources, and ensure project deadlines are met. A PMS
                    typically includes tools for task management, scheduling,
                    and workflow automation, allowing project managers to
                    allocate tasks effectively and prioritize important
                    milestones. It supports different project methodologies,
                    such as Agile, Scrum, or Waterfall, making it adaptable for
                    various industries, including IT, construction, healthcare,
                    and marketing.
                    <br /> <br />
                    Beyond task and time management, a robust Project Management
                    System also facilitates budget tracking, resource
                    allocation, and risk management. By integrating with other
                    business tools such as CRM, ERP, and communication
                    platforms, it ensures seamless data sharing and
                    collaboration across departments. Advanced systems come with
                    automation capabilities, reducing manual efforts and
                    minimizing human errors. Security features, such as
                    role-based access control and data encryption, help protect
                    sensitive project information. Moreover, PMS solutions often
                    include mobile accessibility, allowing teams to stay updated
                    and manage work on the go.
                    <br /> <br />
                  </p>
                </div>
                <div className="col">
                  <h1 className="pt-4 pb-2 text-center fw-bold">
                    <span className={styles.span}> Project</span> Management
                    System
                  </h1>
                  <img
                    className={styles.Img1}
                    data-aos="flip-left"
                    src="https://www.itarian.com/assets-new/images/project-management-software.png"
                    alt="img"
                  ></img>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.div4}>
            <div className="row">
              <div className="col">
                <h1 className="pt-4 pb-2 text-center fw-bold fs-1">
                  <span className={styles.span}> Project </span> Planning and
                  Scheduling
                </h1>
                <img
                  className={styles.Img1}
                  data-aos="flip-left"
                  src="https://cdn-icons-png.flaticon.com/512/12213/12213279.png"
                  alt="img"
                ></img>
              </div>
              <div className="col sub">
                <p>
                  Project Planning and Scheduling are essential components of
                  successful project management, ensuring that tasks are
                  organized, resources are allocated efficiently, and deadlines
                  are met. Project planning involves defining the project scope,
                  setting objectives, identifying key deliverables, and
                  determining the necessary resources such as workforce, budget,
                  and materials. A well-structured plan serves as a roadmap for
                  the project team, outlining responsibilities, workflows, and
                  dependencies between tasks. It also includes risk assessment
                  and mitigation strategies to address potential challenges
                  before they impact the project.
                  <br />
                  <br />
                  Project scheduling is a crucial step that translates the
                  project plan into a detailed timeline, assigning specific
                  tasks to team members with predefined deadlines. This process
                  involves sequencing activities, estimating time durations, and
                  setting dependencies to create an optimized workflow.
                  Techniques such as the Critical Path Method (CPM) and Program
                  Evaluation and Review Technique (PERT) help determine the
                  shortest and most efficient path to project completion.
                  Scheduling tools like Microsoft Project, Primavera, and Trello
                  enable teams to visualize progress, adjust timelines when
                  necessary, and track resource utilization effectively.
                  <br />
                  <br />
                  A well-maintained schedule ensures that the project stays on
                  track, preventing delays and cost overruns. It also provides
                  flexibility to accommodate unexpected changes while
                  maintaining overall project objectives. By integrating
                  effective planning and scheduling, organizations can enhance
                  productivity, improve team collaboration, and achieve
                  successful project delivery with minimal disruptions.
                  <br />
                </p>
              </div>
            </div>
          </div>

          <div className={styles.div5}>
            <div className="row">
              <div className="col sub">
                <p>
                  Task and Workflow Management are critical aspects of project
                  execution, ensuring that work is systematically organized,
                  assigned, and completed efficiently. Task management involves
                  breaking down a project into smaller, manageable tasks,
                  assigning them to the appropriate team members, setting
                  deadlines, and tracking progress. It allows teams to stay
                  focused and ensures accountability by clearly defining
                  responsibilities.
                  <br /> <br />
                  Task management tools, such as Kanban boards, to-do lists, and
                  task prioritization techniques, help streamline workflows by
                  enabling teams to visualize their workload and progress. By
                  using digital task management solutions like Asana, Trello, or
                  Monday.com, organizations can improve communication, prevent
                  bottlenecks, and enhance productivity by ensuring that every
                  team member knows what needs to be done
                  <br /> <br />
                  On the other hand, workflow management focuses on automating
                  and optimizing repetitive processes to improve efficiency and
                  consistency. A workflow is a sequence of tasks that need to be
                  completed in a specific order, often involving multiple team
                  members or departments. Workflow management systems help
                  design, execute, and monitor these processes, reducing human
                  error and eliminating unnecessary delays.
                </p>
              </div>
              <div className="col">
                <h1 className="pt-4 pb-2 text-center fw-bold fs-1">
                  <span className={styles.span}>Task & </span> Workflow
                  Management
                </h1>
                <img
                  className={styles.Img1}
                  data-aos="flip-left"
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyZo0XLw6fxROJvmO_XjygHlOH3kFQMQC8qfdyf-MPz3XbkGCtIzKfMrAXUCnJQuXImys&usqp=CAU"
                  alt="img"
                ></img>
              </div>
            </div>
          </div>

          <div className={styles.div6}>
            <div className="row div4">
              <div className="col">
                <h1 className="pt-3 pb-2 text-center fw-bold ">
                  <span className={styles.span}> Budgeting </span> & Cost 
                  Control
                </h1>
                <img
                  className={styles.Img1}
                  data-aos="flip-left"
                  src="https://cdn-icons-png.flaticon.com/512/10789/10789166.png"
                  alt="img"
                 
                />
              </div>
              <div className="col sub">
                <p>
                  Budgeting and Cost Control are essential components of
                  financial management in project execution, ensuring that
                  resources are allocated effectively and expenses are kept
                  within planned limits. Budgeting involves estimating the total
                  cost of a project by considering all expenses, including
                  labor, materials, equipment, overheads, and unforeseen
                  contingencies. A well-structured budget serves as a financial
                  blueprint, guiding project managers in resource allocation and
                  preventing overspending.
                  <br /> <br />
                  By using tools such as cost estimation models, financial
                  forecasting, and historical data analysis, organizations can
                  create accurate budgets that align with project objectives.
                  Budgeting also includes setting financial benchmarks and
                  allocating funds to different project phases to ensure smooth
                  operations. Proper budget planning helps in securing
                  stakeholder confidence, as it provides transparency and a
                  clear understanding of how financial resources will be
                  utilized throughout the project lifecycle.
                  <br /> <br /> Cost control is the process of monitoring,
                  analyzing, and managing expenses to ensure they do not exceed
                  the allocated budget. It involves tracking real-time
                  expenditures, identifying cost variances, and implementing
                  corrective measures when necessary. <br /> <br />
                </p>
              </div>
            </div>
          </div>

          <div className={styles.hidden}>
            <Suspense>
              <SecondContact />
            </Suspense>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectManagementSystem;
