import React, { useState } from "react";
import { Navbar, Nav, NavDropdown, Container, Button, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Navbar.css";

function MyNavbar() {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const services = [
    { name: "Student Management System", path: "/student-information-system" },
    { name: "School Management Software", path: "/school-management-software" },
    // { name: "Online Admission System", path: "/online-admission-system" },
    { name: "Fees Management System", path: "/fees-management-system" },
    { name: "Employee Management System", path: "/employee-management-system" },
    { name: "Online Examination System", path: "/online-examination-system" },
    { name: "Attendance Management System", path: "/attendance-management-system" },
    // { name: "Higher Education System", path: "/higher-education-system" },
    { name: "Income Expenses Management System", path: "/Income-Expenses-Management-System" },
    { name: "Institute Management Software", path: "/institute-management-software" },
    { name: "College Management Software", path: "/college-management-software" },
    { name: "Enquiry Management System", path: "/Enquiry-management-software" },
    { name: "Shipment Management System", path: "/Shippment-management-software" },
    { name: "Hostel Managment Systems", path: "/hostel-managment-system" },
    { name: "Project Management System", path: "/project-management-system"},
    { name: "StudyPoint Management System", path: "/study-point-management-system"},
    { name: "HR Management System" , path: "/hr-management-system"},
    { name: "Hospital Management Software", path: "/hospital-management-system"},
    { name: "Library"}

   
  ];

  const company = [
    { name: "Organization", path: "/organization" },
    { name: "Team ", path: "/team" },
    { name: "Become Our Partner", path: "/become" },
    { name: "Career" , path: "/career" },
    
    
  ];

  const handleNavbarToggle = () => setExpanded(!expanded);

  const handleServiceClick = () => setExpanded(false);

  return (
    <Navbar className="navbar-custom pt-2" expand="lg" fixed="top" expanded={expanded}>
      <Container fluid className="d-flex align-items-center justify-content-between">
        {/* Brand logo on the left */}
        <Navbar.Brand as={Link} to="/" className="d-flex align-items-center">
          <img
            src="/logoPj.png" // Path to the logo image in the public directory
            alt="Logo"
            className="d-inline-block align-top"
            width="120"
          />
          <span className="company-name fs-5 ms-2">PJSOFTTECH</span>
        </Navbar.Brand>

        {/* Toggle button for mobile view */}
        <Navbar.Toggle onClick={handleNavbarToggle} aria-controls="basic-navbar-nav" />

        {/* Centered Nav items */}
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
          <Nav className="mx-auto text-pop fs-6 fw-bold">
            <Nav.Link as={Link} to="/" onClick={() => setExpanded(false)}>
              Home
            </Nav.Link>

            {/* Service Dropdown with Mega Menu */}
            <NavDropdown
              title="Softwares"
              id="basic-nav-dropdown"
              className="mega-menu"
              onClick={handleServiceClick}
              // onToggle={(isOpen) => setExpanded(isOpen)}
            >
               <Container onClick={(e) => e.stopPropagation()}> {/* Prevent unexpected rerenders */}
                <Row>
                  {services.map((service, index) => (
                    <Col xs={12} sm={6} md={4} key={index}>
                      <NavDropdown.Item
                        as={Link}
                        to={service.path}
                        onClick={handleServiceClick}
                      >
                        {service.name}
                       
                      </NavDropdown.Item>
                    </Col>
                  ))}
                </Row>
              </Container>
            </NavDropdown>

            <Nav.Link as={Link} to="/solutions" onClick={() => setExpanded(false)}>
              Solutions
            </Nav.Link>
            <Nav.Link as={Link} to="/blogs" onClick={() => setExpanded(false)}>
              Blogs
            </Nav.Link>
            <Nav.Link
              href="https://pjsofttech.in/create-account"
              target="_blank"
              rel="noopener noreferrer"
            >
              Plan
            </Nav.Link>
            <Nav.Link as={Link} to="/about" onClick={() => setExpanded(false)}>
              About
            </Nav.Link>
            <Nav.Link as={Link} to="/contact-us" onClick={() => setExpanded(false)}>
              Contact Us
            </Nav.Link>

            {/* Company Dropdown */}
            <NavDropdown
              title="Company"
              id="basic-nav-dropdown"
              onClick={handleServiceClick}
          
            >
              {company.map((company, index) => (
                <NavDropdown.Item
                  as={Link}
                  to={company.path}
                  key={index}
                  onClick={handleServiceClick}
                >
                  {company.name}
                </NavDropdown.Item>
              ))}
            </NavDropdown>

            <Nav.Link as={Link} to="/src/components/Courses/Courses.js" onClick={() => setExpanded(false)}>
              Courses
            </Nav.Link>
            <Nav.Link as={Link} to="/pricing" onClick={() => setExpanded(false)}>
              Pricing
            </Nav.Link>
          </Nav>

          {/* Buttons on the right, hidden by default on mobile */}
          <div className="button-container d-none d-lg-flex ms-auto">
            <Nav.Link>
              <Button
                as={Link}
                to="/contact-us"
                className="Btn1 fs-7 rounded-2 fw-bold"
              >
                Free Demo
              </Button>
            </Nav.Link>
            <Nav.Link>
              <Button
                className="Btn fs-7 rounded-2 fw-bold"
                onClick={() => (window.location.href = "http://pjsofttech.in/")}
              >
                Log in
              </Button>
            </Nav.Link>
          </div>

          {/* Buttons visible inside the dropdown for mobile view */}
          <div className="d-lg-none mt-3">
            <Nav.Link>
              <Button
                as={Link}
                to="/contact-us"
                className="Btn1 fs-7 rounded-2 fw-bold w-100"
              >
                Free Demo
              </Button>
            </Nav.Link>
            <Nav.Link>
              <Button
                className="Btn fs-7 rounded-2 fw-bold w-100"
                onClick={() => (window.location.href = "http://pjsofttech.in/")}
              >
                Log in
              </Button>
            </Nav.Link>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MyNavbar;
