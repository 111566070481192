// import React from "react";
// import { useParams } from "react-router-dom";
// import { Card, CardContent, CardMedia, Typography, Grid } from "@mui/material";
// import yashoda from "../images/yashoda.png";
// import mahastudy from "../images/mahastudynew.png";
// import shipment from "../images/shipment.png";

// const softwarePosts = [
//   {
//     title: "Yashoda Publication",
//     image: yashoda,
//     content: "Yashoda Publication is a publisher of books for various recruitment exams...",
//   },
//   {
//     title: "EBook System",
//     image: mahastudy,
//     content: "An ebook (short for electronic book) is a book publication made available in electronic form...",
//   },
//   {
//     title: "Shipment System",
//     image: shipment,
//     content: "A 'Shipping System' provides information about due date commitments, schedules carriers...",
//   },
// ];

// const SoftwareDetails = () => {
//   const { title } = useParams();

//   return (
//     <div className="container">
//       <h1 className="text-center">Software Development Details</h1>
//       <Grid container spacing={3}>
//         {softwarePosts.map((post, index) => (
//           <Grid item xs={12} sm={6} md={4} key={index}>
//             <Card>
//               <CardMedia component="img" alt={post.title} height="350" image={post.image} />
//               <CardContent>
//                 <Typography variant="h5">
//                   <b>{post.title}</b>
//                 </Typography>
//                 <Typography>{post.content}</Typography>
//               </CardContent>
//             </Card>
//           </Grid>
//         ))}
//       </Grid>
//     </div>
//   );
// };

// export default SoftwareDetails;

import React,{ useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Button,
  Modal
} from "@mui/material";
import styles from "./Solutions.module.css";

const SoftwareDetails = () => {
  const { link } = useParams();
  const location = useLocation();
  const service = location.state?.service;

  const [open, setOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  if (!service) {
    return <Typography variant="h5">Service details not found!</Typography>;
  }

  const handleOpenModal = (service) => {
    setSelectedService(service);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setSelectedService(null);
  };

  return (
    <>
      <div>
        <h1 className={`text-center ${styles.heading}`}>Software Data</h1>
      </div>

      {/* <Box sx={{ flexGrow: 1, p: 3 }}>

      <Grid container spacing={3}>
        {service.detailedData.map((detail, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardMedia component="img" height="140" image={detail.image} alt={detail.title} />
              <CardContent>
                <Typography gutterBottom variant="h5">
                  {detail.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {detail.content}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box> */}

      <div className={`container mb-5 ${styles.container}`}>
        <Grid container spacing={3}>
          {service.detailedData.map((service, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card className={styles.card1}>
                <CardMedia
                  component="img"
                  alt={service.title}
                  height="350"
                  image={service.image}
                  className="service-image" 
                  style={{
                    objectFit: "contain", 
                    borderRadius: "5px", // Optional: Adds rounded corners
                    overflow: "hidden",
                  }}
                />

                <CardContent className={styles.cardContent}>
                  <Typography gutterBottom variant="h5" component="div">
                    <b>{service.title}</b>
                  
                  </Typography>
                  <Typography component="div">
                    {service.content.substring(0, 100)}...
                  </Typography>
                  <Typography>
                  <b>{service.email}</b>
                  </Typography>
                  <Typography>
                  <b> {service.password}</b>
                  </Typography>
                  <div>
                    <a
                      href={service.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Visit Website
                    </a>
                  </div>

                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                    className={styles.readMoreButton}
                    onClick={() => handleOpenModal(service)}
                  >
                    View More
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>

       {/* Modal for detailed view */}
       <Modal open={open} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px",
          }}
        >
          {selectedService && (
            <>
              <Typography variant="h4" gutterBottom>
                {selectedService.title}
              </Typography>
              <CardMedia
                component="img"
                height="300"
                image={selectedService.image}
                alt={selectedService.title}
                style={{
                  objectFit: "contain",
                  borderRadius: "5px",
                  marginTop: "10px",
                }}
              />
              <Typography variant="body1">{selectedService.content}</Typography>
              <Typography variant="body1"><b>{selectedService.email}</b></Typography>
              <Typography variant="body1"><b>{selectedService.password}</b></Typography>

              <Button
                variant="contained"
                color="secondary"
                sx={{ mt: 2 }}
                onClick={handleCloseModal}
              >
                Close
              </Button>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default SoftwareDetails;
