import React, { useEffect, Suspense } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";

import styles from "./ProjectManagementSystem.module.css";
import SecondContact from "../SecondContact/SecondContact";
import image1 from "../images/hospitalmanagement.avif";
import phone from "../images/student-info-detail.png";

const HospitalManagementSystem = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  const blogs = [
    {
      id: 1,
      title: (
        <span style={{ fontSize: "16px" }}>
         
          Patient Registration & Management
        </span>
      ),
      image:
        "https://media.istockphoto.com/id/1344935909/vector/man-at-the-reception-desk-in-the-interior-of-the-hospital-vector-flat-style-cartoon.jpg?s=612x612&w=0&k=20&c=dkrDBvMN9FSCRpty0qNw0yUE99TaLS52zt3aX-WK6cw=",
    },
    {
      id: 2,
      title: <span style={{ fontSize: "16px" }}> Appointment Scheduling </span>,
      image:
        "https://cdn3d.iconscout.com/3d/premium/thumb/appointment-3d-illustration-download-in-png-blend-fbx-gltf-file-formats--calendar-date-schedule-event-day-business-pack-illustrations-3457181.png",
    },
    { 
      id: 3,
      title: (
        <span style={{ fontSize: "17px" }}>
          Electronic Medical Records (EMR)
        </span>
      ),
      image:
        "https://cdn3d.iconscout.com/3d/premium/thumb/medical-record-3d-icon-download-in-png-blend-fbx-gltf-file-formats--report-health-electronic-devices-pack-hospital-icons-9102611.png?f=webp",
    },
    {
      id: 4,
      title: (
        <span style={{ fontSize: "17px" }}>
        
          Billing & Insurance Processing
        </span>
      ),
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKoJ_qtLBX--kaOP6Wx-F8OsEaaEQOrX6DPQ&s",
    },
    {
      id: 5,
      title: <span style={{ fontSize: "16px" }}> Pharmacy Management </span>,
      image:
        "https://cdn3d.iconscout.com/3d/premium/thumb/pharmacy-3d-illustration-download-in-png-blend-fbx-gltf-file-formats--shop-drugstore-dispensary-medicine-medical-pack-healthcare-illustrations-3915252.png?f=webp",
    },

    {
      id: 6,
      title: (
        <span style={{ fontSize: "17px" }}>
        
          Laboratory & Diagnostic Management
        </span>
      ),
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQf2kqnqEKz5-Tw5Frm_72fAneRD-wUedAZ0g&s",
    },

    {
      id: 7,
      title: (
        <span style={{ fontSize: "16px" }}>
          {" "}
          Inventory & Equipment Management
        </span>
      ),
      image:
        "https://cdn3d.iconscout.com/3d/premium/thumb/inventory-management-3d-icon-download-in-png-blend-fbx-gltf-file-formats--parcel-logistics-pack-e-commerce-shopping-icons-8330364.png?f=webp",
    },

    {
      id: 8,
      title: (
        <span style={{ fontSize: "17px" }}> Staff & Payroll Management </span>
      ),
      image:
        "https://cdn3d.iconscout.com/3d/premium/thumb/payroll-3d-icon-download-in-png-blend-fbx-gltf-file-formats--payment-salary-finance-business-and-pack-icons-6166048.png?f=webp",
    },
    {
      id: 9,
      title: <span style={{ fontSize: "17px" }}> Security & Compliance </span>,
      image:
        "https://cdn3d.iconscout.com/3d/premium/thumb/compliance-document-3d-icon-download-in-png-blend-fbx-gltf-file-formats--accept-law-justice-pack-crime-security-icons-5329051.png?f=webp",
    },
    {
      id: 10,
      title: <span style={{ fontSize: "17px" }}> Reporting & Analytics </span>,
      image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZ7OXyoZTRimJ3x6RXhFOQlltAS7KEBJkDaw&s",
    },
    {
      id: 11,
      title: <span style={{ fontSize: "17px" }}> Ambulance Booking </span>,
      image:
        "https://static.vecteezy.com/system/resources/thumbnails/044/764/425/small_2x/medical-ambulance-vehicle-icon-3d-rendering-illustration-png.png",
    },
    {
      id: 12,
      title: <span style={{ fontSize: "17px" }}> Health Checkup </span>,
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR53063WqwRUQtn2WmnVNagVpnNMbXAVTAVDw&s",
    },
    {
      id: 13,
      title: <span style={{ fontSize: "17px" }}> Multiple Hospital Management </span>,
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyTH647N70fnPJWRuW4JgwTPrLYAsEjbvkRw&s",
    },
    {
      id: 14,
      title: <span style={{ fontSize: "17px" }}> Multiple Department Management </span>,
      image:
        "https://img.freepik.com/premium-photo/3d-hierarchy-icon-isolated-white-background-enterprise-management-subordinate-structure-delegating-assignment-distribution-can-be-used-many-purposes-trendy-modern-vector-3d-style_839035-1810482.jpg",
    },
    {
      id: 15,
      title: <span style={{ fontSize: "17px" }}> Hospital App </span>,
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDwHj8zX2u5d1r_NjnEnq7xZj-c3I7ANi9cg&s",
    },
    {
      id: 16,
      title: <span style={{ fontSize: "17px" }}> Doctor App </span>,
      image:
        "https://www.shutterstock.com/image-vector/doctor-online-on-smartphone-app-600nw-2169853543.jpg",
    },
    {
      id: 17,
      title: <span style={{ fontSize: "17px" }}> Nurse App </span>,
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfGqG10IjPwfPREZadBDkVyf4uTKo5tpmRQA&s",
    },
    {
      id: 18,
      title: <span style={{ fontSize: "17px" }}> Patient App </span>,
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5dmABTohLomWIOzX-MsI0iHtixuxQ_VR0xBg9tABrPki0O5vgkC-zetTlebXZUwDu1xA&usqp=CAU",
    },

    {
      id: 19,
      title: <span style={{ fontSize: "19px" }}> 24*7 Support </span>,
      image: "https://cdn-icons-png.flaticon.com/512/5167/5167002.png",
    },
  ];

  return (
    <>
      <div className="container-fluid">
        <div className="container">
          <div className="row" style={{ position: "relative" }}>
            <div className="col">
              <img
                data-aos="flip-left"
                className={styles.Img2}
                src={image1}
                alt="student_photo"
              />
            </div>
            <div className="col div1">
              <h1
                className={styles.h1}
                style={{ width: "900px", height: "180px" }}
              >
                <div className="h1-content fw-bold fs-3 ">
                  <span className={styles.span}>H</span>ospital
                  <br />
                  <span className={styles.span}>M</span>anagement System
                  <br />
                </div>
              </h1>
              <p className={styles.top_text}>
                A Hospital Management System (HMS) is a comprehensive software
                solution designed to streamline healthcare operations. It
                efficiently manages patient records, including demographics,
                medical history, and treatment details. The system facilitates
                appointment scheduling, allowing patients to book consultations
                while helping doctors manage their schedules. It also integrates
                billing and insurance processing, ensuring seamless transactions
                for medical services. An essential feature is Electronic Medical
                Records (EMR), which digitizes patient data for easy access and
                quick retrieval. The HMS includes pharmacy management to track
                prescriptions and control medication invent ory.
              </p>
            </div>
          </div>

          <div className="container-fluid p-5 text-center mt-3">
            <h2 className="fs-1 fw-bold">
              <span className={styles.span}>Simplify</span> salary and payroll
              processing for faculty and administrative staff members by
              generating comprehensive Hospital Management records effortlessly.
            </h2>
            <p className="mt-4">
              A Human Resource Management System (HRMS) simplifies salary and
              payroll processing for faculty and administrative staff by
              automating calculations, tax deductions, and benefits
              administration. It generates comprehensive HR records
              effortlessly, ensuring accurate employee data management,
              attendance tracking, and leave approvals. The system also
              streamlines recruitment, performance evaluations, and compliance
              with labor laws. With a user-friendly self-service portal,
              employees can access pay slips, update information, and request
              leave, reducing administrative workload. Overall, HRMS enhances
              efficiency, accuracy, and transparency in managing workforce
              operations.
            </p>
          </div>
          <div>
            <h2 className="text-center mt-5 key-features fw-bold fs-1">
              <span className={styles.span}> Key </span> Features
            </h2>
          </div>

          <div className={styles.div2}>
            <Container className="mt-5">
              <Grid container spacing={3}>
                {blogs.map((blog) => (
                  <Grid item key={blog.id} lg={4} md={6} className="mb-4">
                    <Card className="d-flex justify-content-center align-items-center bg-light">
                      <CardMedia
                        data-aos="flip-left"
                        component="img"
                        image={blog.image}
                        style={{
                          width: "75px",
                          height: "75px",
                          objectFit: "cover",
                        }}
                      />
                      <CardContent>
                        <Typography
                          variant="h5"
                          component="div"
                          className="d-flex justify-content-start fw-bold align-items-center text-center"
                        >
                          {blog.title}
                        </Typography>
                        <Typography
                          variant="body2"
                          component="p"
                          className="text-center"
                        >
                          {blog.content}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </div>

          <div className="container2 mt-5">
            {/* Phone Mockup */}
            <div className="phone-mockup">
              <img className="img22" data-aos="fade-right" src={phone}></img>
            </div>

            {/* Benefits Section */}
            <div className="benefits-section" data-aos="fade-left">
              <h1 className="pp fw-bold">
                Benefits of{" "}
                <span className="highlight">Hospital Management Software</span>
              </h1>
              <p className="pp fs-4 fw-bold">With PJSOFTTECH, you get:</p>
              <ul
                style={{ fontSize: "0.500rem", lineHeight: "1" }}
                className=""
              >
                <li>
                  <b>Improved Efficiency:</b> Automates administrative tasks
                  like patient registration, appointment scheduling, and
                  billing, reducing manual effort and errors.
                </li>
                <li>
                  <b> Enhanced Patient Care :</b> Provides quick access to
                  patient records, medical history, and test results.
                </li>
                <li>
                  <b> Streamlined Operations :</b> Integrates different hospital
                  departments such as pharmacy, laboratory.
                </li>
                <li>
                  <b> Better Data Security :</b> Ensures patient data protection
                  through encryption, role-based access.
                </li>
                <li>
                  <b> Reduced Operational Costs:</b> Automates routine
                  processes, minimizing paperwork and administrative expenses
                  while improving resource utilization.
                </li>
                <li>
                  <b> Efficient Appointment Management: </b> Helps reduce
                  patient wait times and optimizes doctor schedules, improving
                  overall hospital efficiency.
                </li>
                <li>
                  <b> Accurate Billing & Financial Management : </b> Prevents
                  billing errors, facilitates insurance claims processing, and
                  provides real-time financial tracking.
                </li>
                <li>
                  <b>Inventory & Resource Optimization: </b> Tracks medical
                  supplies, equipment, and drug inventory to prevent shortages
                  and overstocking.
                </li>
                <li>
                  <b>Advanced Reporting & Analytics: </b> Provides insights into
                  hospital performance, patient demographics, and financial
                  health for better decision-making.
                </li>
              </ul>
            </div>
          </div>

          <div className={styles.container}>
            <div className={styles.div3}>
              <div className="row">
                <div className="col sub">
                  <p>
                    A Patient Management System (PMS) plays a crucial role in
                    modern healthcare by efficiently managing patient records,
                    streamlining hospital workflows, and improving overall
                    patient care. One essential way to analyze its effectiveness
                    is through a Patient Admission & Discharge Trend Graph. This
                    line chart visualizes the number of patients admitted and
                    discharged over time, helping hospitals monitor patient flow
                    and optimize resource allocation. By tracking this data
                    daily, weekly, or monthly, hospital administrators can
                    identify trends such as seasonal peaks in admissions, which
                    may indicate outbreaks or high-demand periods.
                    <br /> <br />
                    Another important visualization is the Appointment Booking
                    vs No-Show Rate Bar Chart, which highlights the
                    effectiveness of patient scheduling and engagement. This
                    graph compares the total number of scheduled appointments
                    against the number of missed or canceled visits over a given
                    timeframe. It helps hospitals and clinics identify trends in
                    patient attendance, pinpointing departments or time slots
                    with high no-show rates.High cancellation rates may indicate
                    issues such as poor patient communication, inconvenient
                    appointment times, or scheduling inefficiencies.
                    <br /> <br />
                  </p>
                </div>
                <div className="col">
                  <h1 className="pt-4 pb-2 text-center fw-bold">
                    <span className={styles.span}> Patient </span> Management
                    System
                  </h1>
                  <img
                    className={styles.Img1}
                    data-aos="flip-left"
                    src="https://genamet.com/media/zygllrfl/case-management.png"
                    alt="img"
                  ></img>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.div4}>
            <div className="row">
              <div className="col">
                <h1 className="pt-4 pb-2 text-center fw-bold fs-1">
                  <span className={styles.span}> Staff & </span> Workflow
                  Management System
                </h1>
                <img
                  className={styles.Img1}
                  data-aos="flip-left"
                  src="https://img.freepik.com/free-vector/analyst-working-laptop-with-automation-process-business-process-automation-business-process-workflow-automated-business-system-concept-illustration_335657-2086.jpg"
                  alt="img"
                ></img>
              </div>
              <div className="col sub">
                <p>
                  A Staff & Workflow Management System is essential in hospitals
                  to ensure that healthcare professionals operate efficiently
                  while providing high-quality patient care. One of its key
                  functions is staff scheduling and duty roster management,
                  which ensures that doctors, nurses, and support staff are
                  assigned appropriate shifts based on workload, specialization,
                  and availability. A well-structured scheduling system prevents
                  staff burnout, reduces overtime costs, and ensures that all
                  hospital departments are adequately staffed at all times.
                  <br />
                  <br />
                  A Staff & Workflow Management System is essential in hospitals
                  to ensure that healthcare professionals operate efficiently
                  while providing high-quality patient care. One of its key
                  functions is staff scheduling and duty roster management,
                  which ensures that doctors, nurses, and support staff are
                  assigned appropriate shifts based on workload, specialization,
                  and availability. A well-structured scheduling system prevents
                  staff burnout, reduces overtime costs, and ensures that all
                  hospital departments are adequately staffed at all times.
                  <br />
                  <br />
                  Another critical aspect of a Staff & Workflow Management
                  System is its ability to integrate HR functions, payroll
                  processing, and performance evaluation into one unified
                  platform. By leveraging digital records and analytics,
                  hospitals can track employee attendance, overtime, and
                  productivity to ensure fair compensation and compliance with
                  labor regulations.
                  <br />
                </p>
              </div>
            </div>
          </div>

          <div className={styles.div5}>
            <div className="row">
              <div className="col sub">
                <p>
                An Inventory & Resource Management System is a crucial component of hospital operations, ensuring the efficient tracking, procurement, and distribution of medical supplies, equipment, and medications. Hospitals rely on a well-structured inventory system to maintain optimal stock levels and prevent shortages or overstocking, both of which can lead to financial losses and compromised patient care. 
                  <br /> <br />
                  Hospitals also use resource management systems to track the maintenance and lifecycle of medical equipment, ensuring that devices such as MRI machines, ventilators, and infusion pumps are regularly serviced and function optimally. This proactive approach prevents equipment failures during critical procedures, ensuring uninterrupted patient care.
                  <br /> <br />
                  Additionally, efficient resource allocation reduces unnecessary costs by identifying underutilized assets, enabling hospitals to make informed decisions about purchasing new equipment or reallocating existing resources. Ultimately, an effective Inventory & Resource Management System plays a key role in improving operational efficiency, cutting costs, and enhancing the overall quality of healthcare services.
                </p>
              </div>
              <div className="col">
                <h1 className="pt-4 pb-2 text-center fw-bold fs-1">
                  <span className={styles.span}> Inventory & </span> Resource
                  Management System
                </h1>
                <img
                  className={styles.Img1}
                  data-aos="flip-left"
                  src="https://cdni.iconscout.com/illustration/premium/thumb/inventory-management-illustration-download-in-svg-png-gif-file-formats--flow-logo-online-warehouse-supply-optimization-various-themes-set-044-pack-business-illustrations-6501983.png?f=webp"
                  alt="img"
                ></img>
              </div>
            </div>
          </div>

          {/* <div className={styles.div6}>
            <div className="row div4">
              <div className="col">
                <h1 className="pt-3 pb-2 text-center fw-bold ">
                  <span className={styles.span}> Payroll </span> Management{" "}
                  <br /> System
                </h1>
                <img
                  className={styles.Img1}
                  data-aos="flip-left"
                  src="https://img.freepik.com/free-vector/remittance-money-forward-cash-overseas-direct-funding-give-allowance-spare-sum-getting-payroll-transferring-forex-money-drop-coin-isolated-concept-metaphor-illustration_335657-1214.jpg"
                  alt="img"
                />
              </div>
              <div className="col sub">
                <p>
                  Payroll management is a vital function of Human Resource
                  Management (HRM) that ensures employees receive accurate and
                  timely compensation for their work. A well-integrated HR
                  Management System (HRMS) automates payroll processing by
                  calculating salaries, tax deductions, overtime, bonuses, and
                  benefits. It reduces manual errors, ensures compliance with
                  labor laws, and generates payslips seamlessly.
                  <br /> <br />
                  Payroll systems also manage statutory deductions such as
                  income tax, provident funds, and social security
                  contributions, ensuring legal compliance and reducing
                  financial risks. By integrating payroll with attendance and
                  leave tracking, HRMS ensures fair and transparent salary
                  disbursement based on actual working hours and approved
                  leaves.
                  <br /> <br /> payroll management contributes to employee
                  satisfaction and financial transparency. HRMS platforms
                  provide employees with self-service access to their salary
                  slips, tax documents, and benefits details, reducing HR
                  workload and improving trust. Automated payroll processing
                  enhances efficiency by minimizing administrative burdens and
                  ensuring prompt payments. <br /> <br />
                </p>
              </div>
            </div>
          </div> */}

          <div className={styles.hidden}>
            <Suspense>
              <SecondContact />
            </Suspense>
          </div>
        </div>
      </div>
    </>
  );
};

export default HospitalManagementSystem;
