import React, { useEffect, Suspense } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";

import styles from "./ProjectManagementSystem.module.css";
import SecondContact from "../SecondContact/SecondContact";
import image1 from "../images/hr management.avif";
import phone from "../images/student-info-detail.png";

const HRManagementSystem = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  const blogs = [
    {
      id: 1,
      title: (
        <span style={{ fontSize: "16px" }}>Employee App</span>
      ),
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTd3X_DduYw8CpQt2W36FB8g2SyuHgmlxR4BA&s",
    },
    {
      id: 2,
      title: <span style={{ fontSize: "16px" }}> Attendance Management </span>,
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYBDXboTzUJzX7RwiVlAMuqpsOa7JYR9BcGw&s",
    },
    {
      id: 3,
      title: <span style={{ fontSize: "17px" }}>  Slip </span>,
      image:
        "https://img.freepik.com/premium-vector/flat-design-icon-salary-slip_362714-6064.jpg",
    },
    {
      id: 4,
      title: (
        <span style={{ fontSize: "17px" }}> Interview Schedule </span>
      ),
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKoJ_qtLBX--kaOP6Wx-F8OsEaaEQOrX6DPQ&s",
    },
    {
      id: 5,
      title: (
        <span style={{ fontSize: "16px" }}> Automated Message </span>
      ),
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR32-nVKCxLG0wXVlZLCMfnTXq8B08i6AVJnESQufA4hq0qRtQJA0HIG8cE1XYDZx1nH70&usqp=CAU",
    },

    {
      id: 6,
      title: <span style={{ fontSize: "17px" }}> Offer Letter</span>,
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTjS1Ex-jkNnxeTTIOS4NUkAE3y-Lt9KvZNw&s",
    },

    {
      id: 7,
      title: (
        <span style={{ fontSize: "16px" }}> Termination Letter</span>
      ),
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQztVJFemCs-cK1LBMk9K3XmaCUauO7o4NLGA&s",
    },

    {
      id: 8,
      title: (
        <span style={{ fontSize: "17px" }}> Experience Letter </span>
      ),
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQv8zrRMNRJbkFXXjFdEuQmQ4BnUolNyB4KzimCPsQwMhId4OSnQuWhAXjBzBHT56gUfXo&usqp=CAU",
    },
    {
      id: 7,
      title: (
        <span style={{ fontSize: "17px" }}>
          {" "}
          PF Management{" "}
        </span>
      ),
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvjBbGIkw1uEg0kV1uucPPljkqnAoezfHGrg&s",
    },
    {
      id: 7,
      title: <span style={{ fontSize: "17px" }}> Holiday Management</span>,
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQt80M3FAJouZOu-RsaZi0Ea-ETBLOS2INsmg&s",
    },
    {
      id: 7,
      title: <span style={{ fontSize: "17px" }}> Employee ID Card</span>,
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFUtVx1MG3MeDCUycIFburzV8BYyEsDlIRYA&s",
    },

    {
      id: 18,
      title: <span style={{ fontSize: "19px" }}> 24*7 Support</span>,
      image: "https://cdn-icons-png.flaticon.com/512/5167/5167002.png",
    },
  ];

  return (
    <>
      <div className="container-fluid">
        <div className="container">
          <div className="row" style={{ position: "relative" }}>
            <div className="col">
              <img
                data-aos="flip-left"
                className={styles.Img2}
                src={image1}
                alt="student_photo"
              />
            </div>
            <div className="col div1">
              <h1
                className={styles.h1}
                style={{ width: "900px", height: "180px" }}
              >
                <div className="h1-content fw-bold fs-3">
                  <span className={styles.span}>Human </span>
                  <br />
                  <span className={styles.span}> Resource M</span>anagement <br/>System <br />
                </div>
              </h1>
              <p className={styles.top_text}>
                A Human Resource Management System (HRMS) is a comprehensive
                software solution designed to streamline and automate HR
                processes within an organization. It efficiently manages
                employee records, including personal details, job roles, and
                payroll processing, ensuring accurate salary calculations, tax
                deductions, and benefits administration. The system supports
                recruitment and onboarding by automating job postings, candidate
                tracking, and new hire documentation. It also tracks employee
                attendance, leave requests, and work hours while integrating
                performance management tools for appraisals and goal setting.
                Additionally, HRMS facilitates training and development
                programs, ensuring workforce skill enhancement.
              </p>
            </div>
          </div>

          <div className="container-fluid p-5 text-center mt-3">
            <h2 className="fs-1 fw-bold">
              <span className={styles.span}>Simplify</span> salary and payroll
              processing for faculty and administrative staff members by
              generating comprehensive HR Management records effortlessly.
            </h2>
            <p className="mt-4">
              A Human Resource Management System (HRMS) simplifies salary and
              payroll processing for faculty and administrative staff by
              automating calculations, tax deductions, and benefits
              administration. It generates comprehensive HR records
              effortlessly, ensuring accurate employee data management,
              attendance tracking, and leave approvals. The system also
              streamlines recruitment, performance evaluations, and compliance
              with labor laws. With a user-friendly self-service portal,
              employees can access pay slips, update information, and request
              leave, reducing administrative workload. Overall, HRMS enhances
              efficiency, accuracy, and transparency in managing workforce
              operations.
            </p>
          </div>
          <div>
            <h2 className="text-center mt-5 key-features fw-bold fs-1">
              <span className={styles.span}> Key </span> Features
            </h2>
          </div>

          <div className={styles.div2}>
            <Container className="mt-5">
              <Grid container spacing={3}>
                {blogs.map((blog) => (
                  <Grid item key={blog.id} lg={4} md={6} className="mb-4">
                    <Card className="d-flex justify-content-center align-items-center bg-light">
                      <CardMedia
                        data-aos="flip-left"
                        component="img"
                        image={blog.image}
                        style={{
                          width: "75px",
                          height: "75px",
                          objectFit: "cover",
                        }}
                      />
                      <CardContent>
                        <Typography
                          variant="h5"
                          component="div"
                          className="d-flex justify-content-start fw-bold align-items-center text-center"
                        >
                          {blog.title}
                        </Typography>
                        <Typography
                          variant="body2"
                          component="p"
                          className="text-center"
                        >
                          {blog.content}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </div>

          <div className="container2 mt-5">
            {/* Phone Mockup */}
            <div className="phone-mockup">
              <img className="img22" data-aos="fade-right" src={phone}></img>
            </div>

            {/* Benefits Section */}
            <div className="benefits-section"  data-aos="fade-left">
              <h1 className="pp fw-bold">
                Benefits of{" "}
                <span className="highlight">HR Management Software</span>
              </h1>
              <p className="pp fs-4 fw-bold">With PJSOFTTECH, you get:</p>
              <ul
                style={{ fontSize: "0.500rem", lineHeight: "1" }}
                className=""
              >
                <li>
                  <b>Enhanced Efficiency and Automation:</b> HRMS automates
                  repetitive HR tasks such as payroll processing.
                </li>
                <li>
                  <b> Improved Accuracy and Compliance :</b> HR software ensures
                  compliance with labor laws and regulations by keeping records.
                </li>
                <li>
                  <b> Centralized Employee Data Management :</b> HRMS provides a
                  single platform to store and manage employee records,
                  including personal details.
                </li>
                <li>
                  <b> Better Recruitment and Onboarding :</b> HRMS includes
                  recruitment modules that help in job posting, applicant
                  tracking, resume screening, and interview scheduling.
                </li>
                <li>
                  <b> Effective Payroll and Benefits Management:</b> HR software
                  simplifies payroll calculations, automates salary
                  disbursement.
                </li>
                <li>
                  <b> Employee Self-Service Portals: </b> Most HRMS solutions
                  come with self-service portals where employees can update
                  their information.
                </li>
                <li>
                  <b> Data Security and Confidentiality: </b> HRMS ensures that
                  sensitive employee data is stored securely with encryption,
                  access controls, and backup features.
                </li> 
                <li>
                  <b>Scalability and Customization: </b> HR software can scale
                  according to business needs, allowing organizations to add
                  modules for recruitment, training, or payroll as they grow.
                </li>
                <li>
                  <b>Cost Savings: </b> HRMS helps organizations save costs on
                  administrative work, compliance fines, and payroll errors.
                </li>
              </ul>
            </div>
          </div>

          <div className={styles.container}>
            <div className={styles.div3}>
              <div className="row">
                <div className="col sub">
                  <p>
                    Employee management is a core function of Human Resource
                    Management (HRM) that involves handling all aspects of an
                    employee’s lifecycle within an organization. It includes
                    maintaining comprehensive employee records, from personal
                    details and job history to performance evaluations and
                    payroll data. A well-structured HR management system (HRMS)
                    automates these processes, ensuring accuracy and efficiency
                    in tracking work hours, leaves, and benefits.It also plays a
                    critical role in compliance, ensuring that employee policies
                    align with labor laws and company regulations. By
                    integrating time tracking and attendance monitoring, HRMS
                    reduces errors, streamlines payroll processing, and enhances
                    workforce productivity.
                    <br /> <br />
                    HR systems facilitate performance reviews, goal setting, and
                    feedback mechanisms, allowing organizations to identify top
                    talent and provide necessary training and development
                    programs. Additionally, self-service portals empower
                    employees to access their records, request time off, and
                    update personal details, fostering transparency and reducing
                    HR workload.Beyond administrative tasks, effective employee
                    management focuses on employee engagement, development, and
                    retention.
                    <br /> <br />
                  </p>
                </div>
                <div className="col">
                  <h1 className="pt-4 pb-2 text-center fw-bold">
                    <span className={styles.span}> Employee </span> Management
                    System
                  </h1>
                  <img
                    className={styles.Img1}
                    data-aos="flip-left"
                    src="https://img.freepik.com/premium-vector/online-recruitment-with-businessman-searching-new-employees-smartphone-illustration_138260-1114.jpg"
                    alt="img"
                  ></img>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.div4}>
            <div className="row">
              <div className="col">
                <h1 className="pt-4 pb-2 text-center fw-bold fs-1">
                  <span className={styles.span}> Attendance </span> Management System
                </h1>
                <img
                  className={styles.Img1}
                  data-aos="flip-left"
                  src="https://ubsapp.com/wp-content/uploads/2022/12/Attendance-management.png"
                  alt="img"
                ></img>
              </div>
              <div className="col sub">
                <p>
                  Attendance management is a crucial aspect of Human Resource
                  Management (HRM) that ensures accurate tracking of employee
                  work hours, absences, and leave records. A well-implemented HR
                  Management System (HRMS) automates attendance tracking through
                  biometric systems, RFID cards, GPS-based mobile check-ins, and
                  digital timesheets. This automation eliminates manual errors,
                  reduces time theft, and ensures compliance with company
                  policies and labor laws.
                  <br />
                  <br />
                  Additionally, it integrates seamlessly with payroll
                  processing, ensuring employees are compensated accurately
                  based on their work hours, overtime, and approved leaves. By
                  maintaining real-time attendance data, organizations can
                  enhance workforce productivity, improve resource allocation,
                  and streamline operational efficiency.
                  <br />
                  <br />
                  Beyond tracking presence, attendance management also
                  contributes to workforce planning and employee engagement.
                  HRMS solutions provide insights into attendance trends,
                  absenteeism patterns, and shift scheduling, helping HR teams
                  make data-driven decisions.
                  <br />
                </p>
              </div>
            </div>
          </div>

          <div className={styles.div5}>
            <div className="row">
              <div className="col sub">
                <p>
                  Training and development is a crucial aspect of Human Resource
                  Management (HRM) that focuses on enhancing employees' skills,
                  knowledge, and competencies to improve their performance and
                  career growth. A well-structured HR Management System (HRMS)
                  streamlines training processes by identifying skill gaps,
                  recommending relevant training programs, and tracking
                  employees’ learning progress.
                  <br /> <br />
                  Organizations use HRMS to schedule workshops, e-learning
                  modules, and certification programs, ensuring continuous
                  employee development. By automating training management, HRMS
                  reduces administrative workload, improves training efficiency,
                  and ensures employees receive the necessary knowledge to stay
                  competitive in their roles.
                  <br /> <br />
                  Beyond skill enhancement, training and development play a
                  vital role in employee engagement, retention, and
                  organizational growth. HRMS allows HR teams to monitor
                  training effectiveness through assessments, feedback, and
                  performance evaluations, ensuring that employees apply their
                  learning to real-world tasks.
                </p>
              </div>
              <div className="col">
                <h1 className="pt-4 pb-2 text-center fw-bold fs-1">
                  <span className={styles.span}> Training and</span> Development System
                </h1>
                <img
                  className={styles.Img1}
                  data-aos="flip-left"
                  src="https://cdn3d.iconscout.com/3d/premium/thumb/business-training-3d-illustration-download-in-png-blend-fbx-gltf-file-formats--analytics-logo-meeting-presentation-analysis-and-marketing-pack-illustrations-4639138.png"
                  alt="img"
                ></img>
              </div>
            </div>
          </div>

          <div className={styles.div6}>
            <div className="row div4">
              <div className="col">
                <h1 className="pt-3 pb-2 text-center fw-bold ">
                  <span className={styles.span}> Payroll </span> Management <br/> System
                </h1>
                <img
                  className={styles.Img1}
                  data-aos="flip-left"
                  src="https://img.freepik.com/free-vector/remittance-money-forward-cash-overseas-direct-funding-give-allowance-spare-sum-getting-payroll-transferring-forex-money-drop-coin-isolated-concept-metaphor-illustration_335657-1214.jpg"
                  alt="img"
                />
              </div>
              <div className="col sub">
                <p>
                  Payroll management is a vital function of Human Resource
                  Management (HRM) that ensures employees receive accurate and
                  timely compensation for their work. A well-integrated HR
                  Management System (HRMS) automates payroll processing by
                  calculating salaries, tax deductions, overtime, bonuses, and
                  benefits. It reduces manual errors, ensures compliance with
                  labor laws, and generates payslips seamlessly.
                  <br /> <br />
                  Payroll systems also manage statutory deductions such as
                  income tax, provident funds, and social security
                  contributions, ensuring legal compliance and reducing
                  financial risks. By integrating payroll with attendance and
                  leave tracking, HRMS ensures fair and transparent salary
                  disbursement based on actual working hours and approved
                  leaves.
                  <br /> <br /> payroll management contributes to employee
                  satisfaction and financial transparency. HRMS platforms
                  provide employees with self-service access to their salary
                  slips, tax documents, and benefits details, reducing HR
                  workload and improving trust. Automated payroll processing
                  enhances efficiency by minimizing administrative burdens and
                  ensuring prompt payments. <br /> <br />
                </p>
              </div>
            </div>
          </div>

          <div className={styles.hidden}>
            <Suspense>
              <SecondContact />
            </Suspense>
          </div>
        </div>
      </div>
    </>
  );
};

export default HRManagementSystem;
